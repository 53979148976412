import type { UseFormResetField, UseFormWatch } from 'react-hook-form';

import type { Condition } from '../../client';
import type { FormValue, FormValues } from '../../survey';
import type { Content } from '../../types';
import {
  isBasicExpression,
  isCondition,
  isMultipleSelect,
  isPreEvaluatedExpression,
  isQuestion,
  isSection,
  isSingleSelect,
} from '../../utils/type-narrowing';

export function useConditionalQuestions({
  contentItems,
  watch,
}: {
  contentItems: Content[];
  watch: UseFormWatch<FormValues>;
  resetField: UseFormResetField<FormValues>;
}) {
  const watchAllFields = watch();

  function hasActiveCondition(condition: Condition) {
    // TODO: Implement the logic for `or`. Right now, only `and` is exposed by backend and supported by frontend.
    const cond = condition?.cond;

    const expressions = condition?.expressions ?? [];
    const results = Array(expressions.length).fill(false);

    expressions.forEach((expression, index) => {
      if (isPreEvaluatedExpression(expression)) {
        results.splice(index, 1, expression.result);
      } else if (isBasicExpression(expression)) {
        const { id, operator, values } = expression;
        const watchedValue = watchAllFields[id];
        if (isSingleSelect(watchedValue)) {
          if (operator === 'in') {
            results.splice(index, 1, values.includes(watchedValue));
          } else if (operator === 'not_in') {
            results.splice(index, 1, !values.includes(watchedValue));
          }
        } else if (isMultipleSelect(watchedValue as FormValue[])) {
          if (operator === 'in') {
            results.splice(
              index,
              1,
              values.some((v) => watchedValue?.includes(v))
            );
          } else if (expression.operator === 'not_in') {
            results.splice(index, 1, !values.some((v) => watchedValue?.includes(v)));
          }
        }
      }
      // Recursively process its nested conditions
      if (isCondition(expression)) {
        (expression.expressions as Condition[]).map((condition) => hasActiveCondition(condition));
      }
    });

    return results.every((result) => result);
  }

  function shouldRender(content: Content) {
    // Render anything that is not a question or a section.
    if (!isQuestion(content) && !isSection(content)) {
      return true;
    }

    // Render the section title only when it has, at least, one visible content item.
    if (isSection(content)) {
      const section = content;
      const sectionIndex = contentItems.findIndex((item) => item.id === section.id);
      for (let i = sectionIndex + 1; i < contentItems.length && !isSection(contentItems[i]); i++) {
        if (shouldRender(contentItems[i])) {
          return true;
        }
      }
      return false;
    }

    // Render questions that have no conditions or the ones that have their conditions active.
    const question = content;
    const condition = question.condition;
    const shouldRenderQuestion = !condition || hasActiveCondition(condition);

    return shouldRenderQuestion;
  }

  return { shouldRender };
}
